<style scoped>
.aside{
  /*#1e1e28*/
  /*background-image: linear-gradient(#0160ea,#0097f3,#00befa);*/
  background: linear-gradient(90deg,#1d42ab,#2173dc,#1e93ff);
  /*background: linear-gradient(90deg,#006cff,#399efd)!important;*/
  overflow: hidden;
}
.el-menu {
  /*#1e1e28*/
  /*background-image: linear-gradient(#00befa,#0097f3,#0160ea);*/
  background: linear-gradient(90deg,#1d42ab,#2173dc,#1e93ff);
  /*background: linear-gradient(90deg,#006cff,#399efd)!important;*/
}
.el-submenu__title span {
  color: #fff;
}
.el-menu-item {
  transition: none;
}
/deep/.el-submenu__icon-arrow {
  color: #fff !important;
}
.el-menu-item span {
  color: #fff;
}
.el-menu {
  border-right: none;
}
.el-menu-item {
  /*background-color: #101017;*/
  background: linear-gradient(90deg,#1d42ab,#2173dc,#1e93ff);
  /*background-image: linear-gradient(#00befa,#0097f3,#0160ea);*/
}
.father-sub >>>.el-submenu__title{
  height:48px !important;
  line-height:48px !important;
  background:url(~assets/images/line.png) no-repeat bottom center;
  background-size: 210px 1px !important;
}
.el-submenu .el-menu-item {
  line-height: 45px !important;
  height: 45px;
}
.el-scrollbar__view >>> .el-submenu__title {
  height: 45px;
}
/*去除2级submenu背景下划线*/
.my-submenu >>> .el-submenu__title {
  color: #D7FFFE;
  background: linear-gradient(90deg,#1d42ab,#2173dc,#1e93ff) !important;
}
.aside >>> .el-scrollbar__wrap{
  overflow-x:hidden !important;
}

.el-menu-item:hover, .el-menu-item:focus{
  /*background: #31313e;*/
  background: #2196f3
}

.aside >>> .el-menu-item{
  font-size:14px;
  /*text-align: center;*/
  color:#D7FFFE;
  line-height:30px;
  min-width:inherit;
  position:relative;
  /*padding-left: 30px !important;*/
  /*margin-left:20px;*/

}

.el-menu-item.is-active{
  color:#2A82DB;
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
}



/*.aside ::-webkit-scrollbar-track{*/
/*  background:#2A82DB !important;*/
/*}*/
</style>
<template>
  <el-aside class="aside" width="210px"  >
    <el-scrollbar
        wrap-class="list"
        view-class="view-box"
        style="height:100% !important;overflow-x:hidden !important;margin:0 !important;padding:0 !important;"
        :native="false">
      <el-menu :default-active="$route.path.substring(7)" unique-opened @select="menuSelect">
        <el-submenu :index="item.name" class="father-sub"  v-for="(item,index) in menu" :key="index">
          <template slot="title">
            <i :class="[item.icon ? item.icon : '']" ></i>
            <span>{{ item.name }}</span>
<!--            <i class="el-icon-caret-right right"></i>-->
          </template>

<!--            <el-menu-item :index="subItem.code" v-for="subItem in item.children"  :key="subItem.id"  v-to="'/index/'+subItem.code">-->
<!--              {{subItem.name}}-->
<!--              <i class="el-icon-arrow-right small"></i>-->
<!--            </el-menu-item>-->
          <template v-for="subItem in item.children">
            <el-menu-item v-if="subItem.children.length===0"
                          @click="fatherMenuClick(subItem)"
                          :index="subItem.code"
                          :key="subItem.id"
                          >
              {{subItem.name}}
<!--              <i class="el-icon-arrow-right small"></i>-->
            </el-menu-item>
            <!-- 判断二级菜单（有三级菜单）-->
            <el-submenu class="my-submenu" style="background-color: #101017;color: #babfcb" :key="subItem.id" :index="subItem.name" v-else>
              <template slot="title">{{subItem.name}}</template>
              <el-menu-item style="padding-left: 55px !important;" :index="j.code" v-for="(j,index) in subItem.children" @click="sonMenuClick(j)" v-to="'/index/'+j.code" :key="index">{{j.name}}</el-menu-item>
            </el-submenu>
          </template>

        </el-submenu>


<!--        <template v-for="(item,index) of menu">-->
<!--          &lt;!&ndash; 标题 &ndash;&gt;-->

<!--&lt;!&ndash;          <template v-if="item.children.length">&ndash;&gt;-->

<!--            <el-submenu v-if="item.children.length>0" :key="index" :index="item.id">-->
<!--              <template slot="title">-->
<!--                <i :class="item.icon"></i>-->
<!--                <span>{{item.name}}</span>-->
<!--              </template>-->
<!--              <fant-menu :menu="item.children" ></fant-menu>-->
<!--            </el-submenu>-->

<!--&lt;!&ndash;          </template>&ndash;&gt;-->
<!--          &lt;!&ndash; 选项 &ndash;&gt;-->
<!--&lt;!&ndash;          <template v-else>&ndash;&gt;-->
<!--            <el-menu-item v-else :key="item.id" :index="item.code" v-to="'/index/'+item.code"   @click="menuItemClick($event,item)">-->
<!--              <i :class="item.icon"></i>-->
<!--              <span>{{item.name}}</span>-->
<!--            </el-menu-item>-->
<!--&lt;!&ndash;          </template>&ndash;&gt;-->

<!--        </template>-->

      </el-menu>
    </el-scrollbar>
  </el-aside>
</template>

<script>
window.addEventListener("popstate", function(e) {
  console.log('浏览器监听返回事件',document.querySelector('.el-menu-item.is-active'))
  document.querySelector('.el-menu-item.is-active').click()
}, false);
export default {
  name:'fant-menu',
  props:{
    menu:{type:Array,default:()=>{return []}}
  },
  data() {
    return {
      breadcrumbList: []
    }
  },
  methods: {
    fatherMenuClick(item) {
      if(item.code==="bigdata.home"){
        if(process.env.NODE_ENV !== "production"){
          window.open("#/index/bigdata.home");
          //window.history.back();
          console.log('开发环境 》》》', this.$BASE_URL)
        }else{
          console.log('生产环境 》》》', this.$BASE_URL+"#/index/bigdata.home")
          let a = this.$BASE_URL.slice(0,-3)
          window.open(a + "#/index/bigdata.home");
        }
        //this.$emit('setItem',this.code);
        return false;
      } else {
        this.$router.push('/index/' + item.code)
      }
      // if(item.code==="echarts.pic") return
      // console.log('?????阿斯顿发射点')
      let len = this.breadcrumbList.length - 1
      this.breadcrumbList[len] = item
      //console.log('草草草草草草草草草草',item)
      this.$store.commit('setBread', this.breadcrumbList)

    },
    sonMenuClick(item) {
      let len = this.breadcrumbList.length - 1
      this.breadcrumbList[len] = item
      console.log('xxxxxxxx >>> ',item)
      this.$store.commit('setBread', this.breadcrumbList)


    },
    menuSelect(a,b) {
      console.log('menuSelect>>>',b,)
      let newBre = []
      for(let i = 0; i < b.length; i++) {
        newBre.push({name: b[i],code: ''})
      }
      this.breadcrumbList = newBre
    },
  },
  mounted() {
    // console.log('???????????',this.$route.matched)
    document.querySelector('.is-active').click()
  },
  watch: {
    $route() {
      // console.log('???????????',this.$route.matched)
    }
  },
}

</script>


<template>
  <el-breadcrumb separator=">">
    <el-breadcrumb-item>当前位置 </el-breadcrumb-item>
    <el-breadcrumb-item v-for="(item, index) of $store.state.menu.breadcrumbData" :key="index">{{item.name}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>
<script>
export default {
  name:'fant-breadcrumb',
  props:{
  },
  computed: {
    // 导入vuex菜单数据，菜单折叠状态
    // ...mapGetters(["menuItem","breadcrumb","routeHistoryStack"]),
  },
  data(){
    return{
      messageTreeData:[],
    }
  },
  mounted(){

  },
  methods:{

  }
}
</script>
<style scoped lang="scss">
.el-breadcrumb {
  padding: 15px 10px 0 10px;
}
</style>